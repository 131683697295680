import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="checkout-forms--location-dropdowns"
export default class extends Controller {
  static targets = ["cities", "locations", "stateSelect", "citySelect", "locationSelect"];
  static values = {
    locations: String,
    locationInputName: String,
  };

  connect() {
    this.buildCarWashLocationMap();

    if (this.hasStateSelectTarget) {
      this.setStateDropdownOptions();
    }

    if (this.locations.length === 1) {
      this.setAndHideLocationSelect();
    } else {
      this.setLocationOptions();
    }
  }

  setAndHideLocationSelect() {
    this.setDropdownOptions(this.locationSelectTarget, [
      {
        label: this.locations[0].display_name || this.locations[0].name,
        value: this.locations[0][this.locationInputNameValue],
      },
    ]);
    this.locationsTarget.hidden = true;
    this.locationSelectTarget.value = this.locations[0][this.locationInputNameValue];
  }

  setStateDropdownOptions() {
    this.setDropdownOptions(this.stateSelectTarget, [
      { label: "Select the car wash state...", value: "" },
      ...this.statesWithLocations.map((state) => ({ label: state, value: state })),
    ]);
  }

  buildCarWashLocationMap() {
    this.locations = JSON.parse(this.locationsValue);

    this.buildCitiesByState();
    this.buildStatesWithLocations();
  }

  buildCitiesByState() {
    this.citiesByState = (this.locations || []).reduce((citiesByState, location) => {
      const locationState = location.state || "Other";
      const locationCity = location.city || "Other";

      if (!citiesByState[locationState]) {
        citiesByState[locationState] = new Set();
      }

      citiesByState[locationState].add(locationCity);

      return citiesByState;
    }, {});
  }

  buildStatesWithLocations() {
    const locationStates = new Set();

    (this.locations || []).forEach((location) => {
      const state = location.state || "Other";
      locationStates.add(state);
    });

    this.statesWithLocations = Array.from(locationStates).sort();
  }

  updateState() {
    const selectedState = this.stateSelectTarget.value;
    this.clearCityDropdown();

    this.setLocationOptions();

    if (!selectedState) {
      this.hideCityDropdown();
      return;
    }
    const cities = Array.from(this.citiesByState[selectedState] || []).sort();

    const cityOptions = cities.map((city) => ({ label: city, value: city }));

    this.setDropdownOptions(this.citySelectTarget, [
      { label: "Select the car wash city...", value: "" },
      ...cityOptions,
    ]);

    this.showCityDropdown();
  }

  hideCityDropdown() {
    this.citiesTarget.hidden = true;
  }

  clearCityDropdown() {
    this.citySelectTarget.value = "";
  }

  showCityDropdown() {
    this.citiesTarget.hidden = false;
  }

  updateCity() {
    this.setLocationOptions();
  }

  setLocationOptions() {
    // citySelect and stateSelect targets will not exist if !display_wash_location_state_dropdown
    const selectedCity = this.hasCitySelectTarget ? this.citySelectTarget.value : "";
    const selectedState = this.hasStateSelectTarget ? this.stateSelectTarget.value : "";

    const locations = (this.locations || [])
      .filter((location) => !selectedState || location.state === selectedState)
      .filter((location) => !selectedCity || location.city === selectedCity);

    const locationOptions = locations.map((location) => ({
      label: location.display_name || location.name,
      value: location[this.locationInputNameValue],
    }));
    this.setDropdownOptions(this.locationSelectTarget, [
      { label: "Select a primary location...", value: "" },
      ...locationOptions,
    ]);
  }

  setDropdownOptions(select, options) {
    select.replaceChildren(...options.map(({ label, value }) => new Option(label, value)));
  }
}
