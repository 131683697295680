import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

export default class extends Controller {
  static outlets = ["payment-update"];
  static targets = [
    "total",
    "subtotal",
    "quantity",
    "firstName",
    "lastName",
    "email",
    "phone",
    "licensePlate",
    "locationSelect",
    "totalWithTax",
    "taxEstimate",
    "tax",
    "product",
    "anonymousId",
    "slowMessage",
    "checkoutSuccess",
    "checkoutError",
  ];

  static values = {
    products: String,
    taxForSalesEnabled: Boolean,
  };

  connect() {
    this.estimateTax();
    this.debouncedEstimateTax = debounce(this.estimateTax.bind(this), 1500);
    this.setAnonymousId();
    this.paymentUpdateOutlet.onFormSubmit(this.startSlowMessageCountdown.bind(this));
  }

  checkoutSuccessTargetConnected(target) {
    const parent = target.dataset.parent;
    const url = target.dataset.url;

    if (parent && parent !== "false") {
      window.top.location.href = url;
    } else {
      window.location.href = url;
    }
  }

  checkoutErrorTargetConnected(target) {
    this.cancelSlowMessage();
    this.hideSlowMessage();
    this.paymentUpdateOutlet.hideSpinner();
    this.paymentUpdateOutlet.enableSubmit();
  }

  startSlowMessageCountdown() {
    if (!this.slowMessageTimeoutId) {
      this.slowMessageTimeoutId = setTimeout(() => {
        this.slowMessageTarget.hidden = false;
      }, 15000); // 15 seconds
    }
  }

  cancelSlowMessage() {
    if (this.slowMessageTimeoutId) {
      clearTimeout(this.slowMessageTimeoutId);
      this.slowMessageTimeoutId = null;
    }
  }

  hideSlowMessage() {
    this.slowMessageTarget.hidden = true;
  }

  setAnonymousId() {
    this.anonymousIdTarget.value = document.querySelector("body").dataset.anonymous_id;
  }

  updateTotal(event) {
    let total = 0;

    this.quantityTargets.forEach((quantityTarget) => {
      const quantity = parseInt(quantityTarget.value) || 0;
      const price = parseFloat(quantityTarget.dataset.price) || 0;
      total += (quantity * price) / 100;
    });

    this.totalTarget.textContent = total.toFixed(2);
  }

  async estimateTax() {
    let selectedProductId = null;

    this.productTargets.forEach((product) => {
      if (product.checked) {
        selectedProductId = product.value;
      }
    });

    if (this.shouldEstimateTax(selectedProductId)) {
      const { subtotal, tax, total } = await this.fetchTaxEstimate({
        customer_email: this.emailTarget.value,
        customer_first_name: this.firstNameTarget.value,
        customer_last_name: this.lastNameTarget.value,
        customer_phone: this.phoneTarget.value,
        membership_license_plate: this.hasLicensePlateTarget ? this.licensePlateTarget.value : "",
        product_id: selectedProductId,
        wash_connect_site_id: this.locationSelectTarget.value,
      });

      this.setSubtotal(subtotal);
      this.setTotal(total);
      this.setTax(tax);

      this.showTaxCalculations();
    } else {
      this.hideTaxCalculations();
    }
  }

  updateQuantity(event) {
    // Remove any existing quantity inputs
    document
      .querySelectorAll('input[name="purchase_order_items_attributes[][quantity]"]')
      .forEach((input) => input.remove());

    // Create a new hidden quantity input for the selected product
    const selectedProduct = event.target;
    if (selectedProduct.checked) {
      this.createQuantityInputForSelectedProduct(selectedProduct);
    }
  }

  createQuantityInputForSelectedProduct(selectedProduct) {
    const quantityInput = document.createElement("input");
    quantityInput.type = "hidden";
    quantityInput.name = "purchase_order_items_attributes[][quantity]";
    quantityInput.value = "1";
    selectedProduct.insertAdjacentElement("afterend", quantityInput);
  }

  shouldEstimateTax(selectedProductId) {
    return (
      this.taxForSalesEnabledValue &&
      selectedProductId &&
      selectedProductId !== "" &&
      this.emailTarget.value !== "" &&
      this.firstNameTarget.value !== "" &&
      this.lastNameTarget.value !== "" &&
      this.phoneTarget.value !== "" &&
      this.locationSelectTarget.value !== ""
    );
  }

  async fetchTaxEstimate({
    customer_email,
    customer_first_name,
    customer_last_name,
    customer_phone,
    membership_license_plate,
    product_id,
    wash_connect_site_id,
  }) {
    const response = await fetch("/taxes/estimate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer_email,
        customer_first_name,
        customer_last_name,
        customer_phone,
        membership_license_plate,
        product_id: product_id,
        wash_connect_site_id,
      }),
    });

    const taxEstimate = await response.json();
    return taxEstimate;
  }

  hideTaxCalculations() {
    this.taxEstimateTarget.hidden = true;
  }

  showTaxCalculations() {
    this.taxEstimateTarget.hidden = false;
  }

  setSubtotal(subtotal) {
    this.subtotalTarget.textContent = subtotal;
  }

  setTotal(total) {
    this.totalWithTaxTarget.textContent = total;
  }

  setTax(tax) {
    this.taxTarget.textContent = tax;
  }
}
